import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CryptoJS from "crypto-js";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function ImageUploader({ onUploadSuccess }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  // define show image state
  const [showImage, setShowImage] = useState(false);

  const onFileChange = (event) => {
    
    let file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result;
        // Console log the base64 string
        console.log(base64String);

        // Calculate the size in MB
        let sizeInBytes = base64String.length * 0.75;
        let sizeInMegabytes = sizeInBytes / 1024 / 1024;

        // Check if size is more than 5MB
        if (sizeInMegabytes > 5) {
          alert("File size exceeds 5MB");
          return;
        }

        setSelectedFile(file);
        setPreviewSrc(reader.result);

        // Call upload function immediately after file is selected and preview source is set
        onFileUpload(file, base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const onFileUpload = (file, base64String) => {
    const formData = new FormData();

    // Create current date and time as a string in dd-MM-YYYY,HH:MM am pm format as a string
    let date = new Date();
    let options = {
      timeZone: "Africa/Cairo",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    let dateString = new Intl.DateTimeFormat("en-US", options).format(date);
    let dateInSeconds = Math.floor(date.getTime() / 1000);
    let randomString = Math.random().toString(36).substring(7);
    let hash = CryptoJS.SHA1(
      file.name + dateInSeconds + randomString
    ).toString();

    // Add file extension to hash
    hash += file.name.substring(file.name.lastIndexOf("."));
    
    let data = {
      "base64String": base64String,
      "size": file.size,
      "name": hash,
      "originalName": file.name,
      "uploadDate": dateString
  }

  // Console log debug message
  console.log("Uploading image with the following data:");
  // Console log the data
  console.log(data);

    axios
      .post("https://api.freestore.io/upload", data, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        // Convert response.data to string
        onUploadSuccess(response.data);
        // Show image
        setShowImage(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <p>Select an image (jpg, jpeg, png) to upload:</p>
      {/* Add a red paragraph */}
      <p style={{ color: "red" }}>
        Note: If the image is too large, it will load slowly.
      </p>
      <TextField
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={onFileChange}
      />
      <br />
      {/* {previewSrc && <img src={previewSrc} alt="Preview" />} */}
      {showImage && (
        <img
          src={previewSrc}
          alt="Preview"
          style={{ width: "100px", height: "auto" }}
        />
      )}
      {/* if selectedFile is not null and showimage is false*/}
      {selectedFile && !showImage && (
        <Box sx={{ display: 'flex' }} style={{padding:'10px'}}>
        <CircularProgress />
      </Box>
      )}
    </div>
  );
}

export default ImageUploader;
