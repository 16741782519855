import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ServerControl() {
  const [status, setStatus] = useState('');
  const [timeTaken, setTimeTaken] = useState(0);

  const handleRestart = async () => {
    setStatus('Restarting server...');
    const startTime = Date.now();

    try {
      const response = await axios.get('https://api.freestore.io/restart-dyno');
      if (response.data === 'Dyno restart initiated!') {
        // Introducing a 3-second delay before checking the health
        setTimeout(() => checkServerHealth(startTime), 3000);
      } else {
        setStatus('Failed to restart server.');
      }
    } catch (error) {
      setStatus('Error restarting server.');
    }
  };

  const checkServerHealth = (startTime) => {
    axios.get('https://api.freestore.io/health')
      .then(response => {
        if (response.data === 'Dyno is up and running!') {
          const duration = ((Date.now() - startTime) / 1000).toFixed(2);
          setStatus('Server restarted.');
          setTimeTaken(duration);
        } else {
          setStatus('Still restarting...');
          setTimeout(() => checkServerHealth(startTime), 5000);
        }
      })
      .catch(() => {
        setStatus('Still restarting...');
        setTimeout(() => checkServerHealth(startTime), 5000);
      });
  };

  return (
    <div>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleRestart}  
        style={{ backgroundColor: "#277d97" }}>
        Restart Server
      </Button>
      <Typography variant="h6" style={{ marginTop: 20 }}>
        {status}
      </Typography>
      {timeTaken > 0 && (
        <Typography variant="body1">
          Server restarted in {timeTaken} seconds.
        </Typography>
      )}
    </div>
  );
}
