import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import {  format, parseISO } from "date-fns";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "center",
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

export default function Users() {
  const EgyptianCities = [
    "Alexandria",
    "Aswan",
    "Asyout",
    "Bani Sueif",
    "Beheira",
    "Cairo",
    "Daqahlia",
    "Dumiat",
    "El Bahr El Ahmar",
    "El Ismailia",
    "El Suez",
    "El Wadi El Gedeed",
    "Fayoum",
    "Gharbia",
    "Giza",
    "Helwan",
    "Kafr El Sheikh",
    "Luxor",
    "Matrouh",
    "Menia",
    "Menofia",
    "North Sinai",
    "Port Said",
    "Qalubia",
    "Qena",
    "Sharqia",
    "Sixth of October",
    "Sohag",
    "South Sinai",
  ];

  const Roles = [
    "Student",
    "Entry Level",
    "Experienced (Non-Manager)",
    "Mid Career (Manager)",
    "C Level (CEO, CFO, CTO, etc.)",
    "Other",
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Declare a string state for error levels
  const [error, setError] = useState("");

  // Generate options for select
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };

  const years = generateOptions(getYear(new Date()) - 100, getYear(new Date()));
  const days = generateOptions(1, getDaysInMonth(new Date()));

  // Add Data Form
  // Add User form in a card
  // Initialize form validation
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  // Add user function
  const addUser = async (data) => {
    // Set error to none
    setError("");
    // Log the data
    // Auto generate password and append it to body
    const year = parseInt(data.year);
    const month = parseInt(data.month) - 1; // months are zero-indexed in JS
    const day = parseInt(data.day);

    const date = new Date(year, month, day);

    // Format date as 'Fri, Jun 16, 2023'
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    // Merge dateOfBirth into form data
    const finalData = {
      ...data,
    };

    finalData.gifts_owned = [];
    finalData.wish_list = [];
    finalData.adsClicked = [];
    finalData.isOnTimeout = false;
    finalData.notifications = [
      {
        notification: "Welcome to freestore!",
        redirect: "",
        dateTime: new Date(),
      },
    ];

    finalData.password = Math.random().toString(36).slice(-8);
    console.log(finalData);
    try {
      const response = await axios.post(
        `https://api.freestore.io/user`,
        finalData
      );
      if (response.status === 200) {
        // fetchData();
        console.log("User added successfully!");
        // Clear the data in the form from control
        reset();
        // Refresh the component
        fetchData();
      }
    } catch (error) {
      console.error("There was an error!", error);
      // Set the error
      setError(
        " There was an error creating the user. Please try another email or check your connection."
      );
    }
  };

  // Inside your component...
  const addUserForm = (
    <>
      <Card variant="outlined">
        <CardContent>
          <Title>Add a new user</Title>
          <form onSubmit={handleSubmit(addUser)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* First column fields go here */}
                <Box mb={2}>
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Name is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Invalid email format.",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Gender is required." }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="gender-label"
                        error={Boolean(errors.gender)}
                      >
                        <MenuItem value="Male" key="Male">
                          Male
                        </MenuItem>
                        <MenuItem value="Female" key="Female">
                          Female
                        </MenuItem>
                      </Select>
                    )}
                  />
                  {errors.gender && (
                    <FormHelperText error>
                      {errors.gender.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box mb={2}>
                  <InputLabel htmlFor="location">Location</InputLabel>
                  <Controller
                    name="location"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Location is required." }}
                    render={({ field }) => (
                      <Select {...field} error={Boolean(errors.location)}>
                        {EgyptianCities.map((city) => (
                          <MenuItem value={city} key={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.location && (
                    <FormHelperText error>
                      {errors.location.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Second column fields go here */}
                <Box mb={2}>
                  <InputLabel htmlFor="occupationLevel">
                    Occupation Level
                  </InputLabel>
                  <Controller
                    name="occupationLevel"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Occupation Level is required." }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={Boolean(errors.occupationLevel)}
                      >
                        {Roles.map((role) => (
                          <MenuItem value={role} key={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.occupationLevel && (
                    <FormHelperText error>
                      {errors.occupationLevel.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box mb={2}>
                  <InputLabel htmlFor="phone">Phone</InputLabel>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Phone is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone?.message}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <InputLabel htmlFor="dateOfBirth">Date of Birth</InputLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="dateOfBirth"
                        control={control}
                        rules={{ required: "Date of Birth is required." }}
                        defaultValue={new Date().toISOString().substr(0, 10)}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="date"
                            error={Boolean(errors.dateOfBirth)}
                            helperText={errors.dateOfBirth?.message}
                            variant="outlined"
                            style={{ marginTop: "10px", width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {errors.dateOfBirth && (
                    <FormHelperText error>
                      {errors.dateOfBirth.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box mb={2}>
                  <InputLabel htmlFor="tokens_owned">Tokens Owned</InputLabel>
                  <Controller
                    name="tokens_owned"
                    control={control}
                    defaultValue="0"
                    rules={{ required: "Tokens Owned is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={Boolean(errors.tokens_owned)}
                        helperText={errors.tokens_owned?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={2}>
              {/* If seterror is not empty display in red text */}
              {error && <Typography color="error">{error}</Typography>}
              <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onSubmit={addUser}
                style={{ backgroundColor: "#277d97" }}
              >
                Add User
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );

  // Rest of Form

  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [editingId, setEditingId] = React.useState("");
  const [editingUser, setEditingUser] = React.useState({});

  const fetchData = async () => {
    setLoading(true);
    const result = await axios("https://api.freestore.io/users_admin");
    setUsers(result.data);
    setLoading(false);
    // "" the editing id
    setEditingId("");
  };

  const handleEdit = (user) => {
    setEditingId(user.email);
    setEditingUser(user);
  };

  const handleConfirm = async () => {
    // Try result and handle error
    const result = await axios.put(
      `https://api.freestore.io/user/${editingId}`,
      editingUser
    );
    if (result.status === 200) {
      setEditingId("");
      fetchData();
    }
  };

  const handleDelete = async (id) => {
    const result = await axios.delete(`https://api.freestore.io/user/${id}`);
    if (result.status === 200) {
      fetchData();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Title>Users</Title>
      {addUserForm}
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {/* your header */}
              <TableCell className={classes.header}>Name</TableCell>
              <TableCell className={classes.header}>Email</TableCell>
              <TableCell className={classes.header}>Gender</TableCell>
              <TableCell className={classes.header}>Location</TableCell>
              <TableCell className={classes.header}>Occupation Level</TableCell>
              <TableCell className={classes.header}>Phone</TableCell>
              <TableCell className={classes.header}>Date of Birth</TableCell>
              <TableCell className={classes.header}>Tokens Owned</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .reverse().map((user) =>
                user.email === editingId ? (
                  <TableRow key={user.email}>
                    <TableCell>
                      <TextField
                        value={editingUser.name}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            name: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={editingUser.email}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            email: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={editingUser.gender}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            gender: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={editingUser.location}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            location: e.target.value,
                          })
                        }
                      >
                        {EgyptianCities.map((city) => (
                          <MenuItem value={city}>{city}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select
                        value={editingUser.occupationLevel}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            occupationLevel: e.target.value,
                          })
                        }
                      >
                        {Roles.map((role) => (
                          <MenuItem value={role}>{role}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>

                    <TableCell>
                      <TextField
                        value={editingUser.phone}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            phone: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        value={editingUser.dateOfBirth}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            dateOfBirth: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={editingUser.tokens_owned}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            tokens_owned: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="confirm" onClick={handleConfirm}>
                        <CheckIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(user.email)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={user.email}>
                    {/* your normal table cells here */}
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.gender}</TableCell>
                    <TableCell>{user.location}</TableCell>
                    <TableCell>{user.occupationLevel}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell> {user.dateOfBirth}</TableCell>
                    <TableCell>{user.tokens_owned}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(user)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(user.email)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={users.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </TableContainer>
    </React.Fragment>
  );
}
