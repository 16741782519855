import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth, format, parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Ads from "./AdTable";
import Clients from "./ClientTable";
import Offers from "./OfferTable";

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "left",
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

export default function QRTable() {
  // Rest of Form

  const [QR, setQR] = React.useState([]);
  //   Clients
  const [clients, setClients] = React.useState([]);
  // Ads
  const [ads, setAds] = React.useState([]);
  // Offers
  const [products, setProducts] = React.useState([]);
  // Declare a string state for error levels
  const [error, setError] = useState("");

  // Generate options for select
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };

  const years = generateOptions(getYear(new Date()) - 100, getYear(new Date()));
  const days = generateOptions(1, getDaysInMonth(new Date()));

  // Add Data Form
  // Add qr form in a card
  // Initialize form validation
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  // Add qr function
  const addqr = async (data) => {
    // Set error to none
    setError("");
    // Log the data
    // Auto generate password and append it to body

    // Merge dateOfBirth into form data
    const finalData = {
      ...data,
    };
    // console.log(finalData);
    try {
      const response = await axios.post(
        `https://api.freestore.io/qr`,
        finalData
      );
      if (response.status === 200) {
        // fetchData();
        console.log("qr added successfully!");
        // Clear the data in the form from control
        reset();
        // Refresh the component
        fetchData();
      }
    } catch (error) {
      console.error("There was an error!", error);
      // Set the error
      setError(
        " There was an error creating the qr. Please try another email or check your connection."
      );
    }
  };

  // Inside your component...
  const addqrForm = (
    <>
      <Card variant="outlined">
        <CardContent>
          <Title>Add a new qr</Title>
          <form onSubmit={handleSubmit(addqr)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box mb={2}>
                  <Controller
                    name="QRName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Name is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="QR Name"
                        error={Boolean(errors.QRName)}
                        helperText={errors.QRName?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={2}>
                  <Controller
                    name="adid"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Ad ID is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Ad"
                        error={Boolean(errors.adid)}
                        helperText={errors.adid?.message}
                        fullWidth
                      >
                        {ads.map((ad) => (
                          <MenuItem key={ad.adid} value={ad.adid}>
                            {ad.AdNameFull}
                          </MenuItem>
                        ))}
                        {/* If no ads */}
                        {ads.length === 0 && (
                          <MenuItem disabled>
                            No ads found. Please add an ad first.
                          </MenuItem>
                        )}
                      </TextField>
                    )}
                  />
                </Box>


                <Box mb={2}>
                  <Controller
                    name="productid"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Product ID is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Product"
                        error={Boolean(errors.productid)}
                        helperText={errors.productid?.message}
                        fullWidth
                      >
                        {products.map((offer) => (
                          <MenuItem key={offer.productid} value={offer.productid}>
                            {offer.name}
                          </MenuItem>
                        ))}
                        {/* If no products */}
                        {products.length === 0 && (
                          <MenuItem disabled>
                            No products found. Please add a product first.
                          </MenuItem>
                        )}
                      </TextField>
                    )}
                  />
                </Box> 

                {/* <Box mb={2}>
                  <Controller
                    name="offerid"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Offer ID is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Offer"
                        error={Boolean(errors.offerid)}
                        helperText={errors.offerid?.message}
                        fullWidth
                      >
                        {offers.map((offer) => (
                          <MenuItem key={offer.offerid} value={offer.offerid}>
                            {offer.OfferNameFull}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box> */}

                <Box mb={2}>
                  <Controller
                    name="addedDate"
                    control={control}
                    defaultValue={new Date().toISOString().substring(0, 10)} // Default value is current date
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={2}>
              {/* If seterror is not empty display in red text */}
              {error && <Typography color="error">{error}</Typography>}
              <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onSubmit={addqr}
                style={{ backgroundColor: "#277d97" }}
              >
                Add qr
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );

  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editingId, setEditingId] = React.useState("");
  const [editingqr, setEditingqr] = React.useState({});
  //   Hashmap for ad+offer
  const [adOfferMap, setAdOfferMap] = React.useState({});

  // Hashmap for products
  const [productMap, setProductMap] = React.useState({});

  const fetchData = async () => {
    setLoading(true);
    const result = await axios("https://api.freestore.io/qrs");
    // console log result
    // console.log(result.data.data);
    setQR(result.data.data);

    // Clients
    const clientsResult = await axios("https://api.freestore.io/clients");
    setClients(clientsResult.data);
    // Print Clients
    // console.log(clientsResult.data);
    // Create a easy to look up hashmap from clientID and CLient Names
    const clientMap = {};
    clientsResult.data.forEach((client) => {
      clientMap[client.clientID] = client.name;
    });
    // Print clientMap
    // console.log(clientMap);

    // Ads
    const adsResult = await axios("https://api.freestore.io/ads");
    // Console log adsResult
    console.log(adsResult.data.data);
    if(adsResult.data.data === 'none'){
      // Console log no
      // console.log("No Ads Available");
    }else{
      // Console log Yes
      // console.log("Yes");
      setAds(adsResult.data);
      // In the adResult, add a new property called "name" and set it to the client name
      adsResult.data.forEach((ad) => {
        // Look up client name from clientMap
        let result = "No Client"
        // Console log result
        //   Trim the result to 5 characters
        // Try
        try{
          result = clientMap[ad.clientid];
          result = result.substring(0, 10);
        }
        // Catch
        catch(err){
          result = "Invalid Client"
          console.log(err);
        }
        ad.AdNameFull = result + "..." + ad.title.substring(0, 20) + "...";
      });
      // Add all AddNameFull to the hash map with the adid as the key, and set to state
      const adMap = {};
      adsResult.data.forEach((ad) => {
        adMap[ad.adid] = ad.AdNameFull;
      });
      setAdOfferMap(adMap);
    }
    
    // Offers
    const productResult = await axios("https://api.freestore.io/products");
    if(productResult.data.data !== 'none'){
      // Console log productResult
      console.log(productResult.data);
      setProducts(productResult.data);
      // Create a hashmap for products
      const productMap = {};
      productResult.data.forEach((product) => {
        productMap[product.productid] = product.name;
      });
      setProductMap(productMap);
    }
    setLoading(false);
    // "" the editing id
    setEditingId("");
  };

  const handleEdit = (qr) => {
    setEditingId(qr.QRID);
    setEditingqr(qr);
  };

  const handleConfirm = async () => {
    // Console log the editing qr
    console.log(editingqr);
    // Try result and handle error
    const result = await axios.put(
      `https://api.freestore.io/qr/${editingId}`,
      editingqr
    );
    if (result.status === 200) {
      setEditingId("");
      fetchData();
    }
  };

  const handleDelete = async (id) => {
    const result = await axios.delete(`https://api.freestore.io/qr/${id}`);
    if (result.status === 200) {
      fetchData();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <React.Fragment>
      <Title>QR</Title>
      {addqrForm}
      {/* Table */}
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {/* your header */}
              <TableCell className={classes.header}>
                <div style={{ textAlign: "center" }}>QR</div>
              </TableCell>
              <TableCell className={classes.header}>Date Added</TableCell>
              <TableCell className={classes.header}>Ad</TableCell>
              <TableCell className={classes.header}>Product</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {QR.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .reverse()
              .map((qrsingle) => {
                const date = parseISO(qrsingle.addedDate);
                const formattedDate = format(date, "eee, MMM dd, yyyy");
                return qrsingle.QRID === editingId ? (
                  <TableRow key={qrsingle.QRID}>
                    <TableCell>
                      <TextField
                        value={editingqr.QRName}
                        onChange={(e) =>
                          setEditingqr({
                            ...editingqr,
                            QRName: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        value={editingqr.addedDate}
                        onChange={(e) =>
                          setEditingqr({
                            ...editingqr,
                            addedDate: e.target.value,
                          })
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        select
                        value={editingqr.adID}
                        onChange={(e) =>
                          setEditingqr({
                            ...editingqr,
                            adID: e.target.value,
                          })
                        }
                      >
                        {/* Map ad to menu */}
                        {ads.map((ad) => (
                          <MenuItem key={ad.adid} value={ad.adid}>
                            {ad.AdNameFull}
                          </MenuItem>
                        ))}
                        {/* If no ads */}
                        {ads.length === 0 && (
                          <MenuItem key="none" value="none">
                            No Ads Available
                          </MenuItem>
                        )}
                      </TextField>
                    </TableCell>

                    {/* Add dropdown for products */}
                    <TableCell>
                      <TextField
                        select
                        value={editingqr.productID}
                        onChange={(e) =>
                          setEditingqr({
                            ...editingqr,
                            productID: e.target.value,
                          })
                        }
                      >
                        {products.map((product) => (
                          <MenuItem

                            key={product.productid}
                            value={product.productid}
                          >
                            {product.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                    


                    

                    {/* <TableCell>
                      <TextField
                        select
                        value={editingqr.offerID}
                        onChange={(e) =>
                          setEditingqr({
                            ...editingqr,
                            offerID: e.target.value,
                          })
                        }
                      >
                        {offers.map((offer) => (
                          <MenuItem key={offer.offerid} value={offer.offerid}>
                            {offer.OfferNameFull}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell> */}

                    <TableCell>
                      <IconButton aria-label="confirm" onClick={handleConfirm}>
                        <CheckIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(qrsingle.QRID)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={qrsingle.QRID}>
                    {/* your normal table cells here */}
                    <TableCell>
                      <div style={{ textAlign: "center" }}>
                        {qrsingle.QRName}
                        <br />
                        {/* Show QR code as base 64 string to image */}
                        <img
                          src={qrsingle.image}
                          alt="QR"
                          style={{ width: "100px" }}
                        />
                        {/* Add an a href to download qr */}
                        <br />
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#277d97" }}
                          href={'https://api.freestore.io/qr/'+qrsingle.QRID}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download QR
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{adOfferMap[qrsingle.adID]}</TableCell>
                    {/* Product hashmap from id */}
                    <TableCell>{productMap[qrsingle.productID]}</TableCell>
                    
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(qrsingle)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(qrsingle.QRID)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={QR.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </TableContainer>
    </React.Fragment>
  );
}
